import React, {Component} from 'react'
import ReactPlayer from 'react-player'
import video from '../assets/video/presentacion.mp4'

export default class Video extends Component{
    render(){
        return(
            <div style={{width:'100%', height:'100%', position:'absolute'}}>
                <ReactPlayer 
                    className=''
                    url={video}
                    width='100%'
                    height='100%'
                    playing
                    controls
                    volume='0.8'
                />
            </div>
        );
    }
}
